import { ReactElement, useEffect } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { theme } from '../../themes';
import { axiosConfig, isProduction, streetsUrl } from '../../config';
import { LOCATION_LOGIN_NOTIFICATION_WAIT_TIME, NotificationType } from '../../utils/helpers';
import { useDeviceId } from '../../hooks/useDeviceId';

const Container = styled.div`
  text-align: center;
  padding-top: 15vh;
  padding: 15vh 5vw 0;
  max-width: 1300px;
  margin: 0 auto;
`;

const ExplainerText = styled.p`
  color: ${theme.colors.darkGrey};
  font-size: calc(20px + 1vw);
  padding-top: 5vh;
  text-align: center;
`;
const StyledButton = styled(Button)`
  background-color: ${theme.colors.portlBlue};
  margin-top: 10vh;
  font-size: 40px;
  height: 70px;
  padding: 20px;
`;

const explainerText = [
  'Access to the device’s location is required.',
  'This app will use your location information to identify the location of the ad player, and verify that ads are being served in the same location.',
];

const LocationSvg = styled.svg`
  height: 15vh;
  width: 15vh;
`;
const LocationIcon = () => (
  <LocationSvg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    width='24px'
    fill={theme.colors.darkGrey}
  >
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z' />
    <circle cx={12} cy={9} r='2.5' />
  </LocationSvg>
);

const LocationPrompt = (): ReactElement => {
  const history = useHistory();
  const id = useDeviceId();

  useEffect(() => {
    const checkLocationPermission = () => {
      if (window?.DeviceApi?.hasLocationPermissions()) history.push('/home');
    };

    checkLocationPermission();
    // to check every 5 seconds if permissions have been accepted
    const intervalId = setInterval(checkLocationPermission, 5000);
    const notificationInterval = setTimeout(() => {
      if (id && isProduction) {
        axios.put(`${streetsUrl}/send-email-notification/${id}`, { type: NotificationType.Location }, axiosConfig);
      }
    }, LOCATION_LOGIN_NOTIFICATION_WAIT_TIME);

    return () => {
      clearInterval(intervalId);
      clearTimeout(notificationInterval);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    window?.DeviceApi?.requestLocationPermissions();
  };

  return (
    <Container>
      <LocationIcon />
      <ExplainerText>{explainerText[0]}</ExplainerText>
      <ExplainerText>{explainerText[1]}</ExplainerText>
      <StyledButton variant='contained' color='primary' onClick={handleButtonClick}>
        Allow
      </StyledButton>
    </Container>
  );
};

export default LocationPrompt;
